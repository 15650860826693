import React, { useEffect } from "react";

import Slider from "react-slick";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
const Events = ({data, NewsData}) => {

    useEffect(() => {
        console.log(NewsData);
    }, [NewsData]);
    
    var settings = {
        // loop: true,
        // margin: 10,
        // responsiveClass: true,
        // autoplay: true,
        // dots: true,
        // items: 2,
        // dots: true,
        dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    initialSlide: 0,
        
        responsive: [
           
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }, {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              }
          ]
    };
    return (
        <div>

        
<Slider {...settings}>
              {data.length > 0 ? (data.map((item, index) => (
            <div  key={index}>
                <div className='evt-blk'>
                    <div className="evt-img">
                    {item.images && item.images.length > 0 ? (        <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.images}`} className="img-fluid mx-auto d-block" alt="St. george Collage Musoori" />
                              ):( <img src="/about.jpg" className="img-fluid mx-auto d-block" alt="St. george Collage Musoori" />)
                     }
                        <p className='category'>{item.category}</p>
                    </div>
                    <div className='evt-content'>
                        <div className="evt-title"><h3>{item.title}</h3></div>
                        <div className="evt-dtl"><i class="bi bi-geo-alt-fill"></i> {item.venue}  <div className="date"><i class="bi bi-calendar3"></i> {item.date}</div></div>
                        <p>{item.description}</p>
                    </div>
                </div>
            </div>
            )) ):(
            
            <div>
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src="/about.jpg" />
                        <p className='category'>News</p>
                    </div>
                    <div className='evt-content'>
                        <div className="evt-title"><h3>News Title</h3></div>
                        <div className="evt-dtl"><i class="bi bi-geo-alt-fill"></i> School Campus  <div className="date"><i class="bi bi-calendar3"></i> DD.MM.YYYY</div></div>
                        <p>Stay Tuned For More Updates</p>
                    </div>
                </div>
            </div>)
            }
              {NewsData.length > 0 ? (NewsData.map((item, index) => (
            <div key={index}>
                <div className='evt-blk'>
                    <div className="evt-img">
                    {item.images && item.images.length > 0 ? (        <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.images}`} className="img-fluid mx-auto d-block" alt="St. george Collage Musoori" />
                              ):( <img src="/about.jpg" className="img-fluid mx-auto d-block" alt="St. george Collage Musoori" />)
                     }
                        <p className='category'>{item.category}</p>
                    </div>
                    <div className='evt-content'>
                        <div className="evt-title"><h3>{item.title}</h3></div>
                        <div className="evt-dtl"><i class="bi bi-geo-alt-fill"></i> {item.venue}  <div className="date"><i class="bi bi-calendar3"></i> {item.date}</div></div>
                        <p>{item.description}</p>
                    </div>
                </div>
            </div>
            ))):(
            <div>
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src="/about.jpg" />
                        <p className='category'>News</p>
                    </div>
                    <div className='evt-content'>
                        <div className="evt-title"><h3>News Title</h3></div>
                        <div className="evt-dtl"><i class="bi bi-geo-alt-fill"></i> School Campus  <div className="date"><i class="bi bi-calendar3"></i> DD.MM.YYYY</div></div>
                        <p>Stay Tuned For More Updates</p>
                    </div>
                </div>
            </div>)
            }
         
         </Slider>
        </div>
    );
};
export default Events;