import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
const Virtualtour = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Campus Walk Through</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li> 
                                    <li>Campus Walk Through</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                        <h3 className="inner-main-title"><span className="dark-bg">Campus Walk  </span> <span className="light-bg">Through</span></h3>
                        </div>
                        <div className="virtual_tab">
                            <div className='wallk_through'>
                                <h3>CAMPUS WALK THROUGH</h3>
                                </div>
                          
                        </div>
                        
                    </div>
                    <div className='virtual_block'>
                            <div className='row'>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href="https://my.matterport.com/show/?m=j1e51sBuYGn" target='_blank'>
                                        <div className='virtual_blk'>
                                            <img src="/church.png" className='img_view'></img>
                                            <img src="/church.gif" className='gif_view'></img>
                                            <h3>CHURCH</h3>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href="https://my.matterport.com/show/?m=aFMrtMkPjsB" target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/doormatre.png" className='img_view'></img>
                                        <img src="/doormatre.gif" className='gif_view'></img>
                                        <h3>DORMITORY</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=8FHyb5gMe8f' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/pool.png" className='img_view'></img>
                                        <img src="/pool.gif" className='gif_view'></img>
                                        <h3>SWIMMING POOL</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=BXJD2wnnbmf' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/building.png" className='img_view'></img>
                                        <img src="/building.gif" className='gif_view'></img>
                                        <h3>INFRASTRUCTURE</h3>
                                    </div> </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=fDoWSXAKGNk' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/virtual_cross.png" className='img_view'></img>
                                        <img src="/virtual_cross.gif" className='gif_view'></img>
                                        <h3>CHAPEL</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=DwD2jDxyMyj' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/virtual_gallery.png" className='img_view'></img>
                                        <img src="/virtual_gallery.gif" className='gif_view'></img>
                                        <h3>ARCHIVE GALLERY</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=6qi1QvUC76J' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/virtual_facility.png" className='img_view'></img>
                                        <img src="/virtual_facility.gif" className='gif_view'></img>
                                        <h3>FACILITIES</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=BpwPHGbu5tC' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/academic.png" className='img_view'></img>
                                        <img src="/academic.gif" className='gif_view'></img>
                                        <h3>ACADEMICS</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=8K3yFviomne' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/sports.png" className='img_view'></img>
                                        <img src="/sports.gif" className='gif_view'></img>
                                        <h3>SPORTS FACILITIES</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=8wjMnbutqf9' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/admin.png" className='img_view'></img>
                                        <img src="/admin.gif" className='gif_view'></img>
                                        <h3>ADMIN BLOCK</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='https://my.matterport.com/show/?m=SY1DaLxH78n' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/laboratory_view.png" className='img_view'></img>
                                        <img src="/laboratory.gif" className='gif_view'></img>
                                        <h3>SCIENCE BLOCK</h3>
                                    </div>
                                    </a>
                                </div>
                                </div>
                                <div className='360_view'>
                            <h3>360 VIEW</h3>
                                </div> 
                                <div className='row'>



                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='#' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/administration.png" className='img_view'></img>
                                        <img src="/administration.gif" className='gif_view'></img>
                                        <h3>ADMINISTRATION</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='#' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/class.png" className='img_view'></img>
                                        <img src="/class.gif" className='gif_view'></img>
                                        <h3>CLASSES</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='#' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/bed.png" className='img_view'></img>
                                        <img src="/bed.gif" className='gif_view'></img>
                                        <h3>DORMS</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='#' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/virtual_facility.png" className='img_view'></img>
                                        <img src="/virtual_facility.gif" className='gif_view'></img>
                                        <h3>FACILITIES</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='#' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/history.png" className='img_view'></img>
                                        <img src="/history.gif" className='gif_view'></img>
                                        <h3>HISTORY & CULTURE</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='#' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/laboratory_view.png" className='img_view'></img>
                                        <img src="/laboratory.gif" className='gif_view'></img>
                                        <h3>LAB</h3>
                                    </div>
                                    </a>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-6'>
                                    <a href='#' target='_blank'>
                                    <div className='virtual_blk'>
                                        <img src="/sports.png" className='img_view'></img>
                                        <img src="/sports.gif" className='gif_view'></img>
                                        <h3>SPORTS</h3>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Virtualtour