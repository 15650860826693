import React, { useRef, useState, useEffect } from 'react';
import Counter from './Counter';

const MainCounter = () => {
  const [visible, setVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setVisible(true);
        }
      },
      {
        threshold: 0.5, // Adjust this value to your needs
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    
       
      <div ref={sectionRef}>
        {visible && (
          <div className="d-flex row ratio">
            <Counter title="Total Students" targetNumber={700} />
            <Counter title="Nationalities" targetNumber={7} />
            <Counter title="Class Rooms"  targetNumber={30} />
            <Counter title="Teacher Student Ratio" targetNumber={1+":"+30} />
          </div>
        )}
      </div>
    
  );
};

export default MainCounter;
