
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import EntryToSgc from './pages/entry-to-sgc'; 
import EntryToIVVIII from './pages/entry-to-class-iV-Viii';  
import EntryToIX from './pages/entry-to-IX';  
import EntryToXI from './pages/entry-to-XI';   
import RulesRegulation from './pages/rules-regulations';   
import About from './pages/about';   

import PricipalMessageInner from './pages/principal-message';
import Academic from './pages/academic-facilities';
import Sports from './pages/sports';
import Residential from './pages/residential';
import Timeline from './pages/timeline';
import Events from './pages/Events';
import News from './pages/News';
import Gallery from './pages/Gallery';
import SubGallery from './pages/SubGallery';
import TopperXII from './pages/TopperXII';
import TopperX from './pages/TopperX';
import SchoolSong from './pages/schoolSong';
import WeeklyMenu from './pages/weeklyMenu';
import Newsletter from './pages/newsletter';
import AimObjectives from './pages/aims-objectives';
import MissionVision from './pages/mission-vision';
import Philosophy from './pages/philosophy';
import Values from './pages/values';
import CampusAmenities from './pages/campusAmenities';
import Environmental from './pages/environmental';
import Contact from './pages/contact';
import SubFest from './pages/subFest';
import Virtualtour from './pages/virtual-tour';
import './App.css';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/entry-to-sgc" element={<EntryToSgc />} />
          <Route path="/virtual-tour" element={<Virtualtour />} />
          <Route path="/about" element={<About />} />
          <Route path="/entry-to-class-iV-Viii" element={<EntryToIVVIII />} />
          <Route path="/entry-to-IX" element={<EntryToIX />} /> 
          <Route path="/entry-to-XI" element={<EntryToXI />} />
          <Route path="/rules-regulations" element={<RulesRegulation />} />
          <Route path="/principal-message" element={<PricipalMessageInner />} />
          <Route path="/academic-facilities" element={<Academic />} />
          <Route path="/sports" element={<Sports />} />
          <Route path="/residential" element={<Residential />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/News" element={<News />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/SubGallery" element={<SubGallery />} />
          <Route path="/TopperXII" element={<TopperXII />} />
          <Route path="/TopperX" element={<TopperX />} />
          <Route path="/schoolSong" element={<SchoolSong />} />
          <Route path="/weeklyMenu" element={<WeeklyMenu />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/aims-objectives" element={<AimObjectives />} />
          <Route path="/mission-vision" element={<MissionVision />} />
          <Route path="/philosophy" element={<Philosophy />} />
          <Route path="/values" element={<Values />} />
          <Route path="/campusAmenities" element={<CampusAmenities />} />
          <Route path="/environmental" element={<Environmental />} /> 
          <Route path="/contact" element={<Contact />} /> 
          <Route path="/subFest" element={<SubFest />} /> 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
