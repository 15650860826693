import React, { useState } from "react";
import Header from '../component/Header';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';

const Newsletter = () => {
    // State to manage selected year and tab
    const [selectedYear, setSelectedYear] = useState('2024');
    const [selectedTab, setSelectedTab] = useState('man_year');

    // Sample data, should ideally come from props or an API
    const content = {
        '2024': {
            man_year: [
                { id: 1, link: "https://drive.google.com/file/d/1IYdOrktJ0mxl8FijSdT0_Fix_MY_sFkK/view?usp=drive_link", img: "/newsletter6.JPG", title: "The Manorite" },
                { id: 4, link: "https://drive.google.com/file/d/1dPAtpr28PI7s_cmHb-dt3V1irpgGUK1v/view?usp=drive_link", img: "/newsletter5.JPG", title: "Manor Musings 2023" },
            ],
            man_quater: [
                { id: 2, link: "https://drive.google.com/file/d/1ubpEQT2RlUKl8zelq0S7ITs4Jj_xHEO4/view?usp=drive_link", img: "/newsletter8.JPG", title: "The Manor Times" },
            ],
            man_monthly: [
                { id: 3, link: "https://drive.google.com/file/d/1NijST1hiWjWgBWGdJ_udSaifTMOdM7lZ/view?usp=drive_link", img: "/e-magazine1.JPG", title: "Manor Musings Edition 1" },
                { id: 5, link: "https://drive.google.com/file/d/11Og5fLWOBoYnDqINrhbr2s4s8YS_e7te/view?usp=drive_link", img: "/newsletter2.JPG", title: "The Battle Of Houses" },
                { id: 6, link: "https://drive.google.com/file/d/1q4DEKOQvoc86ZDEQNWHwyROGDlb0JmBh/view?usp=drive_link", img: "/newsletter3.JPG", title: "Manor Musings June Edition 2024" }
            ],
            man_handbook: [
                { id: 7, link: "https://drive.google.com/file/d/1J7byi1pkxP6dgr6Nu7-WLJEexM42e8sw/view?usp=drive_link", img: "/newsletter7.JPG", title: "Parents' Handbook 2024" }
            ]
        },
        '2023': {
            man_year: [
                // Add 2023 content here
            ],
            man_quater: [],
            man_monthly: [],
            man_handbook: []
        }
    };

    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>NewsLetter</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Gallery</li>
                                    <li>|</li>
                                    <li>E-NewsLetter</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section newsletter">
                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <h3 className="inner-main-title">
                                <span className="dark-bg">E - </span>
                                <span className="light-bg"> Newsletter</span>
                            </h3>
                        </div>
                        <div className='col-md-12'>
                            <div className="yearSelection">
                                Year:
                                <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                </select>
                            </div>
                            <div className="tabSelect">
                                <div 
                                    className={`tab ${selectedTab === 'man_year' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_year')}
                                >
                                    Manorite - YearBook
                                </div>
                                <div 
                                    className={`tab ${selectedTab === 'man_quater' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_quater')}
                                >
                                    Manor Times - Quarterly Magazine
                                </div>
                                <div 
                                    className={`tab ${selectedTab === 'man_monthly' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_monthly')}
                                >
                                    Manor Musings - Monthly Magazine
                                </div>
                                <div 
                                    className={`tab ${selectedTab === 'man_handbook' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_handbook')}
                                >
                                    Parents Handbook
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                {content[selectedYear][selectedTab].map((item) => (
                                    <div key={item.id} className='col-lg-4 col-md-6'>
                                        <div className="card">
                                            <Link to={item.link} target="_blank">
                                                <img src={item.img} className="img-fluid" alt={item.title} />
                                                <h3>{item.title}</h3>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Newsletter;
