import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Slider = () => {
    var settings = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoplay: true, autoplayTimeout: 8000,
        dots: false,
        items: 1,

    };
    return (
        <>
            <div className='slider-main'>
                <div className='login-link'>
                    <a href="http://103.134.46.162:81/" target='_blank'><img src="/login-img.gif" /></a>
                </div>
                <OwlCarousel className="owl-theme main-slider" loop  {...settings}>
                    {/* <div className="item">
                    <video autoPlay loop muted id="videoPlayer" width="100%" height="100%">
                            <source src="/demo.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        
                        <div className="content">
                            <div className='h1-bg'><h1>Where Learning Meets <br></br> nature's Embrace</h1></div>
                            <i class="bi bi-arrow-right"></i>
                        </div>
                    </div> */}
     
                    <div className="item">
                        <img src="sld-1.jpg" alt="St George School" className='img-fluid' />
                        <div className="content">
                            <div className='h1-bg'><h1>Where Champions<br></br>Are Made</h1></div>
                            
                            <i class="bi bi-arrow-right"></i>
                        </div>
                    </div> 
                    <div className="item">
                        <img src="sld-2.jpg" alt="St George School" className='img-fluid' />
                        <div className="content">
                            <div className='h1-bg'><h1>Focused Creativity<br></br> In Action!</h1></div>
                            
                            <i class="bi bi-arrow-right"></i>
                        </div>
                    </div> 
                    <div className="item">
                        <img src="sld-3.jpg" alt="St George School" className='img-fluid' />
                        <div className="content">
                        <div className='h1-bg'><h1>The Rhythm<br></br>Of Achievement!</h1></div>
                            
                            
                            <i class="bi bi-arrow-right"></i>
                        </div>
                    </div> 
                    <div className="item">
                        <img src="sld-4.jpg" alt="St George School" className='img-fluid' />
                        <div className="content">
                            <div className='h1-bg'><h1>Where Focus <br></br> Meets Imagination!</h1></div>
                            
                            <i class="bi bi-arrow-right"></i>
                        </div>
                    </div> 
                </OwlCarousel>
           
            </div>
        </>
    )
}
export default Slider