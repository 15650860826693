import React from "react"; 


const Footer = () => {
    return (
        <>
            <footer>
                <div class="footer animateMe" data-animation="fadeInUp"><div class="container-fluid"><div class="row"><div class="col-lg-9">
                    <img src="/footer-logo.png" class="logo"  alt="St. George's College"  /></div>
                    <div class="col-lg-3"><div class="socialMedia"><p>FOLLOW US</p>
                    <ul><li><a href="https://www.facebook.com/StGeorgesCollegeOfficial/" target="_blank" rel="noreferrer"><i class="bi bi-facebook"></i></a></li>
                    <li><i class="bi bi-youtube"></i></li>
                    <li><a href="https://www.instagram.com/st.georgescollegeofficial/?hl=en" target="_blank" rel="noreferrer"><i class="bi bi-instagram"></i></a></li>
                    <li><i class="bi bi-linkedin"></i></li></ul></div></div></div><div class="row"><div class="col-lg-3 col-md-6">
                        <div class="ofc-link">
                        <ul>
                        <li><span>Principal's office : </span><div>
                            <a href="tel:+91 7060311771">+91 7060311771 </a> | <a href="tel:+91 7060311770">7060311770</a></div></li>
                            <li><span>Email: </span><a href="mailto: principal@sgconline.ac.in">principal@sgconline.ac.in</a></li>
                            </ul></div></div>
                            <div class="col-lg-3 col-md-6"><div class="quick-link">
                            <ul>
                                    <li><a href="/">Home</a></li>
                                {/* <li>Who We Are</li>
                                <li>Origin</li> */}
                                <li><a href="/principal-message">Principal’s Desk</a></li>
                                {/* <li>Milestone</li>
                                <li>Facilities</li> */}
                                <li><a href="/gallery">Photo Gallery</a></li>
                                {/* <li>Video Gallery</li>
                                <li>Contact Us</li> */}
                                </ul></div></div>
                                <div class="col-lg-3  col-md-6"><div class="cont-dtl">
                                    <h3>Postal Address</h3>
                                    <p><i class="bi bi-geo-alt-fill"></i> St. George’s College Barlowganj, Mussoorie - 248122,  Uttarakhand (India)</p>
                                    <p><i class="bi bi-envelope-fill"></i> <a href="mailto: principal@sgconline.ac.in">principal@sgconline.ac.in</a></p>
                                    <p><i class="bi bi-globe"></i> <a href="http://www.sgconline.ac.in/">www.sgconline.ac.in</a></p></div></div>
                                    <div class="col-lg-3 col-md-6">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d110081.17317287669!2d78.00514216483047!3d30.435062620358774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3908d735f43ecf9d%3A0xc808bfe0b1026e74!2sBarlow%20Ganj%2C%20Mussoorie%2C%20Uttarakhand%20248122!3m2!1d30.4350885!2d78.0875435!5e0!3m2!1sen!2sin!4v1715165059400!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div></div>
                                        </div></div>
                
                 
                <div className="copyright">
                    copyright © ️2024 St. george's college. All right reserved | Designed & Developed by Entab Infotech : CampusCare
                </div>
            </footer>
        </>
    )
}
export default Footer;